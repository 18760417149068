import "../css/style.css"
import React from "react"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import share from "../images/Asset_4.png"
import icon from "../images/icon.png"
import app from "../images/app.png"
import f from "../images/f.png"
import mail from "../images/mail.png"
import bird from "../images/bird.png"
import link from "../images/link.png"

export const pageQuery = graphql`
  query NotePageQuery($id: ID!) {
    gcms {
      nota(where: { id: $id }) {
        id
        titulo
        mainTag
        tags
        stage
        seccionTitulo
        seccionUrl
        autor
        fechaDeCreacion
        createdAt
        resumen
        texto {
          html
        }
        url
        destacado
        imagenes {
          url(
            transformation: {
              image: { resize: { fit: clip, width: 1200 } }
              document: { output: { format: webp } }
            }
          )
        }
      }
    }
  }
`

const NoteDetails = ({
  data: {
    gcms: { nota },
  },
  data: {
    gcms: {
      nota: { imagenes },
    },
  },
  data: {
    gcms: {
      nota: { texto },
    },
  },
}) => (
  <>
    <Helmet>
      <script async src="https://platform.twitter.com/widgets.js"></script>
      <meta name="robots" content="noindex,nofollow" />
      <meta property="og:image" content={imagenes.url} />
      <meta
        property="og:url"
        content={`https://muchofucho.mx/${nota.seccionUrl}/${nota.url}`}
      />
    </Helmet>
    <SEO
      title={nota.titulo}
      description={nota.resumen}
      image={imagenes.url}
      url={`/${nota.seccionUrl}/${nota.url}`}
    />
    <section className="templatenota-section page-border">
      <div className="grid-container-templatenota">
        <div className="templatenota-banner-section"></div>
        <div>
          <div className="templatenota-div-sec">
            <Link className="templatenota-sec" to={`/${nota.seccionUrl}`}>
              {nota.seccionTitulo}
            </Link>
          </div>
          <img className="templatenota-img" alt="" src={imagenes.url} />
          <p className="templatenota-title">{nota.titulo}</p>
          <div className="templatenota-span"></div>
          <p className="templatenota-autor">
            Por{" "}
            <em>
              <strong>{nota.autor}</strong>
            </em>{" "}
            | {nota.fechaDeCreacion}
          </p>
          <div className="templatenota-share-div">
            <img className="templatenota-share-img" src={share} alt="" />
            <img
              className="templatenota-mf-icon"
              src={icon}
              alt="muchofuchomx icono"
            />
            <p className="templatenota-share-text">COMPARTIR</p>
            <a
              href={`http://www.facebook.com/sharer.php?u=https://muchofucho.mx/${nota.seccionUrl}/${nota.url}`}
              target="_blank"
            >
              <img className="templatenota-share-icon" src={f} alt="" />
            </a>
            <a
              href={`whatsapp://send?text=https://muchofucho.mx/${nota.seccionUrl}/${nota.url}`}
              data-action="share/whatsapp/share"
            >
              <img className="templatenota-share-icon" src={app} alt="" />
            </a>
            <a
              href={`mailto:?subject=${nota.titulo}&body=https://muchofucho.mx/${nota.seccionUrl}/${nota.url}`}
            >
              <img className="templatenota-share-icon" src={mail} alt="" />
            </a>
            <a
              href={`https://twitter.com/share?url=https://muchofucho.mx/${nota.seccionUrl}/${nota.url}`}
              target="_blank"
            >
              <img className="templatenota-share-icon" src={bird} alt="" />
            </a>
          </div>
          <div
            className="templatenota-richtext"
            dangerouslySetInnerHTML={{ __html: texto.html }}
          />
          <div
            className="templatenota-tags"
            dangerouslySetInnerHTML={{ __html: nota.tags }}
          ></div>
          <iframe
            className="templatenota-facebook-comment-box"
            src={`https://www.facebook.com/plugins/comments.php?api_key=704681073488445&locale=es_ES&numposts=4&title=facebookcomments&url=https://muchofucho.mx/${nota.seccionUrl}/${nota.url}&xid=muchofucho.mx/${nota.seccionUrl}/${nota.url}`}
            frameborder="0"
            title="fb_comments"
            allowtransparency="allowtransparency"
            scrolling="no"
            width="100%"
            height="100%"
          ></iframe>
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className="templatenota-banner-section"></div>
      </div>
    </section>
  </>
)

export default NoteDetails
